import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { NotificationService } from '../../../../../../../libs/utils/ng-shared-components/src';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { OrderDto } from '../../../../api/dtos/OrderDto';
import { PaymentOrderDto } from '../../../../api/dtos/PaymentOrderDto';
import { PaymentOrderFiltersDto } from '../../../../api/dtos/PaymentOrderFiltersDto';
import { ApiClientAdminPaymentOrder } from '../../../../api/endpoints/ApiClientAdminPaymentOrder';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { OrderStatus } from '../../../../api/enums/OrderStatus';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { PaymentOrderCreateComponent } from './payment-order-create.component';
import { PaymentOrderEditComponent } from './payment-order-edit.component';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { FilterDateRangeType } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/enums/FilterDateRangeType';
import { FilterEntityType } from '../../../view-models/FilterEntityType';

@Component({
  selector: 'mf-payment-order-grid',
  templateUrl: './payment-order-grid.component.html',
  styleUrls: ['./payment-order-grid.component.scss'],
})
export class PaymentOrderGridComponent implements OnInit {
  constructor(
    public _apiClientAdminPaymentOrder: ApiClientAdminPaymentOrder,
    private _modalService: ModalService,
    private _notificationService: NotificationService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() hideFilters = false;
  @Input() operationId: string;
  @Input() showCreateButton = false;
  @Input() disableCreateButton = false;
  filters: PaymentOrderFiltersDto;

  ngOnInit(): void {
    this.filters = new PaymentOrderFiltersDto();
    this.filters.operationId = this.operationId;
  }

  columns: IGridColumn<PaymentOrderDto>[] = [
    {
      field: nameof<PaymentOrderDto>((o) => o.operation.operationNumber),
      header: 'Operación',
      width: 5,
      filter: {
        propertyName: nameof<PaymentOrderFiltersDto>((o) => o.operationNumber),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<PaymentOrderDto>((o) => o.orderNumber),
      header: 'Número',
      width: 5,
      filter: {
        propertyName: nameof<PaymentOrderFiltersDto>((o) => o.orderNumber),
        type: FilterType.Number,
      },
    },
    {
      field: nameof<PaymentOrderDto>((o) => o.externalId),
      header: 'Id externo',
      width: 5,
      filter: {
        propertyName: nameof<PaymentOrderDto>((o) => o.externalId),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<PaymentOrderDto>((o) => o.person.fullName),
      header: 'Persona',
      width: 20,
      filter: {
        propertyName: nameof<PaymentOrderFiltersDto>((o) => o.person),
        type: FilterType.Entity,
        entityType: FilterEntityType.Person,
      },
    },
    {
      field: nameof<OrderDto>((o) => o.status),
      header: 'Estado',
      width: 10,

      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OrderStatus,
      },
      filter: {
        propertyName: nameof<PaymentOrderFiltersDto>((o) => o.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.OrderStatus,
      },
    },
    {
      field: nameof<OrderDto>((o) => o.isRefund),
      header: 'Devolución',
      width: 5,
      cellOptions: { type: CellValueType.Boolean },
    },
    { field: nameof<PaymentOrderDto>((o) => o.destinationBankAccount.bank.name), header: 'Banco', width: 10 },
    { field: nameof<PaymentOrderDto>((o) => o.destinationBankAccount.accountNumber), header: 'Cuenta', width: 10 },
    {
      field: nameof<PaymentOrderDto>((o) => o.destinationBankAccount.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<PaymentOrderFiltersDto>((o) => o.currency),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<PaymentOrderDto>((o) => o.amount),
      header: 'Monto',
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (paymentOrderDto: PaymentOrderDto) => {
        this._modalService.openLargeModal(PaymentOrderEditComponent, {
          openData: {
            paymentOrderId: paymentOrderDto.id,
          },
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Ver tracking',
      icon: Icons.list,
      onClick: (paymentOrderDto: PaymentOrderDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: paymentOrderDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Eliminar',
      icon: Icons.delete,
      hide: (paymentOrderDto: PaymentOrderDto) => paymentOrderDto.status != OrderStatus.Draft,
      onClick: (paymentOrderDto: PaymentOrderDto) => {
        this._modalService.openYesOrNoModal('¿Está seguro de eliminar la orden de pago?', {
          onCloseSuccess: async () => {
            await this._apiClientAdminPaymentOrder.delete(paymentOrderDto.id);
            this._notificationService.showSuccess('La orden ha sido eliminada');
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<PaymentOrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminPaymentOrder.getPaged(search, httpApiRequestOptions);

  openNewPaymentOrderPopup() {
    this._modalService.openLargeModal(PaymentOrderCreateComponent, {
      openData: {
        operationId: this.operationId,
      },
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
