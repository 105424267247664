import { Component } from '@angular/core';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  selector: 'mf-field-radio-boolean',
  templateUrl: './field-radio-boolean.component.html',
  styleUrls: ['./field-radio-boolean.component.scss'],
})
export class FieldRadioBooleanComponent extends FieldMatBaseComponent<boolean | null> {
  override get controlType(): string {
    return 'field-radio-boolean';
  }

  handleClick(clickedValue: boolean | null): void {
    console.log('clickedValue', clickedValue);
    console.log('value', this.value);
    if (this.value === clickedValue) {
      this.value = null;
    }
  }
}
