<mat-radio-group
  [ariaLabel]="label"
  [(ngModel)]="value">
  <div>
    <div>{{ label }}</div>
    <div>
      <mat-radio-button
        [value]="true"
        (click)="handleClick(true)"
        >Sí</mat-radio-button
      >
    </div>
    <div>
      <mat-radio-button
        [value]="false"
        (click)="handleClick(false)"
        >No</mat-radio-button
      >
    </div>
  </div>
</mat-radio-group>
