// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { FileUploadResponseDto } from "../../../../../libs/utils/ng-shared-components/src/lib/fields/field-file-upload/dtos/FileUploadResponseDto";

@Injectable({providedIn: 'root'})
export class ApiAdminUploadFile {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  uploadAccreditationByAccountVoucher(fileList: FileList, httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<FileUploadResponseDto> {
    return this._httpApiClient.uploadFile('/admin/upload-file/accreditation-by-account-voucher', fileList, httpApiRequestOptions);
  }

}
