export enum FilterType {
  Text = 1,
  Number = 2,
  Date = 3,
  Enum = 4,
  EnumList = 5,
  NumericRange = 6,
  Boolean = 7,
  Check = 8,
  Entity = 9,
}
