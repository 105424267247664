import { Component } from '@angular/core';
import { AccreditationsByAccountDto } from '../../../../api/dtos/AccreditationsByAccountDto';
import { ApiAdminAccreditationsByAccount } from '../../../../api/endpoints/ApiAdminAccreditationsByAccount';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-accreditations-by-account-details',
  templateUrl: './accreditations-by-account-details.component.html',
  styleUrls: ['./accreditations-by-account-details.component.scss'],
})
export class AccreditationsByAccountDetailsComponent {
  constructor(private _apiAdminAccreditationsByAccount: ApiAdminAccreditationsByAccount, modalService: ModalService) {
    this.load(modalService.openData);
  }

  accreditationsByAccount: AccreditationsByAccountDto;

  async load(id: string) {
    this.accreditationsByAccount = await this._apiAdminAccreditationsByAccount.getById(id);
  }
}
