// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class ResultItemDto<T>  {
  item: T;
  selected: boolean = false;
}

export interface ResultItemDtoFormGroup<T>  {
  item?: any;
  selected?: any;
}
