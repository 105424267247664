import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiAdminConciliation } from '../../../api/endpoints/ApiAdminConciliation';
import { ConciliationDto } from '../../../api/dtos/ConciliationDto';

@Component({
  selector: 'conciliation-details',
  templateUrl: './conciliation-details.component.html',
  styleUrls: ['./conciliation-details.component.scss'],
})
export class ConciliationDetailsComponent {
  constructor(private _apiAdminConciliation: ApiAdminConciliation, modalService: ModalService) {
    this.load(modalService.openData);
  }

  conciliation: ConciliationDto;

  async load(id: string) {
    this.conciliation = await this._apiAdminConciliation.getById(id);
  }
}
