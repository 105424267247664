import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { CompanyDto, CompanyDtoFormGroup } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';
import { PersonType } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { LoginService } from '../../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { Roles } from '../../../../api/enums/Roles';

@Component({
  selector: 'mf-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent {
  constructor(private _cfb: CustomFormBuilder, private _loginService: LoginService) {}

  @Input()
  companyDto: CompanyDto;

  @Output()
  save = new EventEmitter<CompanyDto>();

  personType = PersonType;
  isSuperAdmin = this._loginService.userIsInRole([Roles[Roles.SuperAdmin]]);

  companyForm = this._cfb.group<CompanyDtoFormGroup>({
    name: ['', Validators.required, 'Razón social'],
    commercialName: ['', Validators.required, 'Nombre fantasía'],
    idDocument: [null, Validators.required],
    email: ['', Validators.required, 'Email'],
    address: [null, Validators.required],
    isExemptFromOriginDeclaration: [false, Validators.required, 'No declara origen de fondos'],
  });

  ngOnInit(): void {
    this.companyForm.patchValue(this.companyDto);
  }
}
