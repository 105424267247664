// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { GatewayBankAccountDto } from "../dtos/GatewayBankAccountDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { GatewayBankAccountFiltersDto } from "../dtos/GatewayBankAccountFiltersDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminGatewayBankAccount {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    gatewayBankAccountDto: GatewayBankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayBankAccountDto> {
    return this._httpApiClient.post("/admin/gateway-bank-account", gatewayBankAccountDto, httpApiRequestOptions);
  }

  edit(
    gatewayBankAccountDto: GatewayBankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayBankAccountDto> {
    return this._httpApiClient.put("/admin/gateway-bank-account", gatewayBankAccountDto, httpApiRequestOptions);
  }

  updateGatewayBalance(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/gateway-bank-account/update-balance", null, httpApiRequestOptions);
  }

  setAsDefault(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/gateway-bank-account/set-as-default/" + id, null, httpApiRequestOptions);
  }

  suggest(
    gatewayBankAccountFilterDto: SuggestSearchDto<GatewayBankAccountFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<GatewayBankAccountDto>> {
    return this._httpApiClient.post("/admin/gateway-bank-account/suggest", gatewayBankAccountFilterDto, httpApiRequestOptions);
  }

}
