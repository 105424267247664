// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { FilterRangeNumericDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';
import { GatewayBankAccountDto } from './GatewayBankAccountDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';

export class BankMovementFiltersDto extends FiltersDto {
  amount: FilterRangeNumericDto;
  gatewayBankAccountId: string | null = null;
  gatewayBankAccount: GatewayBankAccountDto;
  currencyList: number[] = [];
  createdDate: FilterRangeDateDto;
  movementTypeList: number[] = [];
  conciliationRowId: string = '';
  conciliationId: string = '';
  reference: string = '';
}

export interface BankMovementFiltersDtoFormGroup extends FiltersDtoFormGroup {
  amount?: any;
  gatewayBankAccountId?: any;
  gatewayBankAccount?: any;
  currencyList?: any;
  createdDate?: any;
  movementTypeList?: any;
  conciliationRowId?: any;
  conciliationId?: any;
  reference?: any;
}
