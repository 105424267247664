@if (gridFilter.entityType == filterEntityType.Bank) {
<mf-autocomplete-bank
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
} @else if (gridFilter.entityType == filterEntityType.Accreditation) {
<mf-autocomplete-accreditation
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
} @else if (gridFilter.entityType == filterEntityType.Client) {
<mf-autocomplete-client
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
} @else if (gridFilter.entityType == filterEntityType.Person) {
<mf-autocomplete-person
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
} @else if (gridFilter.entityType == filterEntityType.BankAccount) {
<mf-autocomplete-bank-account
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
} @else if (gridFilter.entityType == filterEntityType.GatewayBankAccount) {
<mf-autocomplete-gateway-bank-account
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
}@else if (gridFilter.entityType == filterEntityType.User) {
<mf-autocomplete-user
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
}
