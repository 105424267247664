import { Component } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { AccreditationDto } from '../../../api/dtos/AccreditationDto';
import { ApiAdminAccreditation } from '../../../api/endpoints/ApiAdminAccreditation';

@Component({
  selector: 'mf-accreditation-undo',
  templateUrl: './accreditation-undo.component.html',
  styleUrls: ['./accreditation-undo.component.scss'],
})
export class AccreditationUndoComponent {
  constructor(private _apiAdminAccreditation: ApiAdminAccreditation, private _modalService: ModalService, private _cfb: CustomFormBuilder) {
    this.load(_modalService.openData);
  }

  accreditation: AccreditationDto;
  accreditationByAccountCount: number;
  numberIsValid = false;

  async load(id: string) {
    this.accreditation = await this._apiAdminAccreditation.getById(id);
    this.accreditationByAccountCount = this.accreditation?.accreditationsByAccounts.length;
  }

  undoValidation = this._cfb.group({
    accreditationsByAccount: [
      '',
      {
        validators: [Validators.required],
        asyncValidators: [
          async (control: AbstractControl): Promise<ValidationErrors | null> => {
            this.numberIsValid = Number(control.value) === this.accreditationByAccountCount;
            return this.accreditationByAccountCount !== Number(control.value)
              ? {
                  forbiddenName: {
                    displayMessage: 'El número de transferencias a revertir no coincide con el indicado',
                  },
                }
              : null;
          },
        ],
      },
      'Transferencias a revertir',
    ],
    checkUndoAccreditation: [false, [Validators.required], 'Confirmar cancelación'],
  });

  async undoAccreditation() {
    if (!this.undoValidation.valid) {
      this.undoValidation.markAllAsTouched();
      return;
    }
    await this._apiAdminAccreditation.cancel(this.accreditation.id);
    this._modalService.closeAsSuccess('Accreditación cancelada correctamente');
  }

  closeModal() {
    this._modalService.closeAsCancel();
  }
}
