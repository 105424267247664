import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-order-grid-page',
  templateUrl: './order-grid-page.component.html',
  styleUrls: ['./order-grid-page.component.scss'],
})
export class OrderGridPageComponent {
  constructor(private _modalService: ModalService) {
    this.transferId = this._modalService.openData;
  }

  transferId: string;
}
