<mf-page title="Acreditaciones por cuenta">
  @if (accreditationsByAccountList() && accreditationsByAccountList()!.length > 0) { @for (accreditationByAccount of
  accreditationsByAccountList(); track $index) {
  <mf-row>
    <mf-accreditations-by-account-card [accreditationByAccount]="accreditationByAccount" />
  </mf-row>
  } } @else {
  <h3>No se realizaron transferencias en este proceso de acreditación</h3>
  }
</mf-page>
