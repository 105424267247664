// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { ClientDto } from './ClientDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { FilterRangeNumericDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';

export class OperationFiltersDto extends FiltersDto {
  clientId: string | null = null;
  client: ClientDto;
  createdDate: FilterRangeDateDto;
  currency: number | null = null;
  currencyList: number[] = [];
  externalId: string = '';
  inactive: boolean | null = null;
  operationNumber: number | null = null;
  reason: string = '';
  statusList: number[] = [];
  amount: FilterRangeNumericDto;
}

export interface OperationFiltersDtoFormGroup extends FiltersDtoFormGroup {
  clientId?: any;
  client?: any;
  createdDate?: any;
  currency?: any;
  currencyList?: any;
  externalId?: any;
  inactive?: any;
  operationNumber?: any;
  reason?: any;
  statusList?: any;
  amount?: any;
}
