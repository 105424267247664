import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ConciliationDto } from '../../../api/dtos/ConciliationDto';
import { ConciliationFiltersDto } from '../../../api/dtos/ConciliationFiltersDto';
import { RunJobParameter } from '../../../api/dtos/RunJobParameter';
import { ApiAdminConciliation } from '../../../api/endpoints/ApiAdminConciliation';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ConciliationDetailsComponent } from './conciliation-details.component';
import { TrackingPopupComponent } from '../../components/track-grid/tracking-popup.component';

@Component({
  selector: 'mf-conciliation-grid',
  templateUrl: './conciliation-grid.component.html',
  styleUrls: ['./conciliation-grid.component.scss'],
})
export class ConciliationGridComponent {
  constructor(private _apiAdminConciliation: ApiAdminConciliation, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;

  filters = new ConciliationFiltersDto();
  jobBody: RunJobParameter;

  columns: IGridColumn<ConciliationDto>[] = [
    {
      field: nameof<ConciliationDto>((e) => e.conciliationNumber),
      header: 'Número de conciliación',
      width: 20,
    },
    {
      field: nameof<ConciliationDto>((e) => e.executionDate),
      header: 'Fecha',
      width: 15,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      field: nameof<ConciliationDto>((e) => e.executor.naturalPerson.fullName),
      header: 'Ejecutor',
      width: 15,
    },
    {
      field: nameof<ConciliationDto>((e) => e.country),
      header: 'País',
      width: 15,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_shared.Country,
        enumNameModule: moduleName.shared,
      },
    },
    {
      field: nameof<ConciliationDto>((e) => e.uploadTransfersCount),
      header: 'Transferencias cargadas',
      width: 20,
    },
    {
      field: nameof<ConciliationDto>((e) => e.executionNotes),
      header: 'Notas',
      width: 25,
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver detalles',
      icon: Icons.search,
      onClick: (item: ConciliationDto) => {
        this._modalService.openRightModal(ConciliationDetailsComponent, { openData: item.id });
      },
    },
    {
      label: 'Ver tracking',
      icon: Icons.details,
      onClick: (item: ConciliationDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, { openData: item.id });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<ConciliationFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminConciliation.getPaged(search, httpApiRequestOptions);

  conciliate() {
    this._modalService.openYesOrNoModal('¿Está seguro que desea cargar datos y conciliar en DaMap?', {
      onCloseSuccess: async () => {
        await this._apiAdminConciliation.sendTransfersAndConciliate();
        this.grid.reloadPage();
      },
    });
  }
}
