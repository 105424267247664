<mf-form
  [acceptButtonHide]="true"
  [formGroup]="accreditationsByAccountVoucherForm">
  <mf-card>
    <mf-column>
      <mf-row-display>
        <h3>
          {{ accreditationByAccount.sourceAccount.bank.displayText }}
        </h3>
        <span> {{ accreditationByAccount.type | translate : enumName.AccreditationsByAccountType }}</span>
      </mf-row-display>
      <mf-row-display label="Nro de cuenta">
        {{ accreditationByAccount.sourceAccount.currency | customCurrency }}
        {{ accreditationByAccount.sourceAccount.accountNumber }}
      </mf-row-display>
      <mf-row-display label="Monto"
        >{{ accreditationByAccount.sourceAccount.currency | customCurrency }}
        {{ accreditationByAccount.amountTransferred | number }}</mf-row-display
      >
      <mf-row-display label="Cantidad de transferencias"> {{ accreditationByAccount.transfers.length }}</mf-row-display>
    </mf-column>
    <mf-column>
      @if (currentStatus() == accreditationByAccountStatus.Pending) {
      <p>Descargar archivo</p>
      <mf-button
        text="Descargar"
        (click)="downloadFileAndUpdateStatus(accreditationByAccount)">
      </mf-button>
      } @else if (currentStatus() == accreditationByAccountStatus.FileDownloaded) {

      <div class="card-column center-element">
        <mf-field-file-upload
          (uploadCompleted)="save()"
          formControlName="imageUrl"
          [endpointUpload]="endpoint" />
      </div>
      } @else if (currentStatus() == accreditationByAccountStatus.Completed) {
      <div class="card-column center-element">
        <mf-icon
          [icon]="icons.checkCircle"
          size="bigger">
        </mf-icon>
        <strong>Listo!</strong>
      </div>
      }
    </mf-column>
    <mf-column>
      <div class="card-column center-element">
        @if (currentStatus() != accreditationByAccountStatus.Pending) {
        <mf-row class="center-element">
          <mf-button-secondary
            text="Volver a descargar"
            [icon]="icons.download"
            (click)="downloadFile(accreditationByAccount)">
          </mf-button-secondary>
        </mf-row>
        }
        <mf-row class="center-element">
          <mf-button-secondary
            text="Ver transferencias"
            [icon]="icons.openInNew"
            (click)="navigateToTransfers(accreditationByAccount)">
          </mf-button-secondary>
        </mf-row>
      </div>
    </mf-column>
  </mf-card>
</mf-form>
