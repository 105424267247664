import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() icon: string;
  @Input() size: 'small' | 'medium' | 'large' | 'bigger' = 'medium';
  @Input() numberBadge: number = 0;
  @Input() rotate = 0;

  get styleSize(): number {
    return this.size === 'small' ? 16 : this.size === 'medium' ? 24 : this.size === 'large' ? 32 : 64;
  }
}
