import { Component, Input } from '@angular/core';
import { moduleName } from '../../../../../../../ts-shared/src/lib/module-names';
import { Icons } from '../../../../icon/icons';
import { EnumTranslateProviderService } from '../../../../services/enum-translate-provider.service';

import { FilterType } from '../../../interface/filter-type';
import { IGridFilter } from '../../../interface/grid-filter';
import { GridFilterService } from '../../service/grid-filter.service';
import { FilterRangeNumericDto } from '../../../../../../../ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';
import { FilterRangeDateDto } from '../../../../../../../ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { FilterDateRangeType } from '../../../../../../../ts-shared/src/lib/api/searching/enums/FilterDateRangeType';
import { enumName_searching } from '../../../../../../../ts-shared/src/lib/api/searching/enums/_enumName.searching';
import { DateProviderService } from '../../../../services/date-provider-service';

export class FilterTagValue {
  constructor(public value: any, public label: string, public isArrayItem?: boolean) {}
}

@Component({
  selector: 'mf-grid-filter-tag',
  templateUrl: './grid-filter-tag.component.html',
  styleUrls: ['./grid-filter-tag.component.scss'],
})
export class GridFilterTagComponent {
  constructor(private _enumTranslateProviderService: EnumTranslateProviderService, private dateProviderService: DateProviderService) {}

  @Input()
  filter: IGridFilter;

  @Input()
  gridFilterService: GridFilterService;

  icons = Icons;

  removeFilter(tagValue: FilterTagValue) {
    this.gridFilterService.clearFilter(this.filter, tagValue.isArrayItem ? tagValue.value : undefined);
  }

  private enumValue(value: number, isArrayItem: boolean): FilterTagValue {
    return new FilterTagValue(
      value,
      this._enumTranslateProviderService.translateEnum(this.filter.enumName ?? '', value, this.filter.enumNameModule),
      isArrayItem
    );
  }

  get tagValues(): FilterTagValue[] {
    const filterValue = this.gridFilterService.getFilterAppliedValue(this.filter);
    if (this.filter.type == FilterType.EnumList) {
      return filterValue.map((c: number) => this.enumValue(c, true));
    }

    if (this.filter.type == FilterType.Enum) {
      return [this.enumValue(filterValue, false)];
    }

    if (this.filter.type == FilterType.Number) {
      const filterRangeNumericDto = filterValue as number;
      return [new FilterTagValue(filterRangeNumericDto, filterValue)];
    }

    if (this.filter.type == FilterType.NumericRange) {
      const filterRangeNumericDto = filterValue as FilterRangeNumericDto;
      let label = filterRangeNumericDto.min ? 'Desde ' + filterRangeNumericDto.min + ' ' : '';
      label += filterRangeNumericDto.max ? 'Hasta ' + filterRangeNumericDto.max : '';

      return [new FilterTagValue(filterRangeNumericDto, label)];
    }

    if (this.filter.type == FilterType.Boolean) {
      return [new FilterTagValue(filterValue, filterValue ? 'Sí' : 'No')];
    }

    if (this.filter.type == FilterType.Date) {
      const filterRangeDataValue = filterValue as FilterRangeDateDto;
      if (filterRangeDataValue.type == FilterDateRangeType.Range) {
        return [
          new FilterTagValue(
            filterRangeDataValue,
            this.dateProviderService.toString(filterRangeDataValue.min) +
              ' a ' +
              this.dateProviderService.toString(filterRangeDataValue.max)
          ),
        ];
      }

      if (filterRangeDataValue.type == FilterDateRangeType.Date) {
        return [new FilterTagValue(filterRangeDataValue, '' + filterRangeDataValue.specificDate)];
      }

      return [
        new FilterTagValue(
          filterValue,
          this._enumTranslateProviderService.translateEnum(enumName_searching.FilterDateRangeType, filterValue.type, moduleName.searching)
        ),
      ];
    }

    if (this.filter.type == FilterType.Entity) {
      return [new FilterTagValue(filterValue, filterValue['displayText'])];
    }

    return [new FilterTagValue(filterValue, filterValue, false)];
  }
}
