// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { ResultDto, ResultDtoFormGroup } from './ResultDto';
import { PagedResultItemDto } from './PagedResultItemDto';

export class PagedResultDto<TEntity> extends ResultDto<TEntity> {
  currentPageIndex: number = 0;
  currentPageItemCount: number = 0;
  totalPageCount: number = 0;
  includedFields: string[] = [];
  pageItemList: PagedResultItemDto<TEntity>[] = [];
  usingContinuationToken: boolean = false;
  pageContinuationToken: string = '';
}

export interface PagedResultDtoFormGroup<TEntity> extends ResultDtoFormGroup<TEntity> {
  currentPageIndex?: any;
  currentPageItemCount?: any;
  totalPageCount?: any;
  includedFields?: any;
  pageItemList?: any;
  usingContinuationToken?: any;
  pageContinuationToken?: any;
}
