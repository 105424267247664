// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PaymentTransactionFiltersDto, PaymentTransactionFiltersDtoFormGroup } from './PaymentTransactionFiltersDto';
import { AccreditationsByAccountDto } from './AccreditationsByAccountDto';
import { FilterRangeNumericDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';
import { BankDto } from './BankDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { ClientDto } from './ClientDto';

export class TransferFiltersDto extends PaymentTransactionFiltersDto {
  accreditationConsolidationNumber: number | null = null;
  accreditationOriginNumber: number | null = null;
  accreditationByAccountId: string | null = null;
  accreditationByAccount: AccreditationsByAccountDto;
  amount: FilterRangeNumericDto;
  bankSourceId: string | null = null;
  bankSource: BankDto;
  bankDestinationId: string | null = null;
  bankDestination: BankDto;
  createdDate: FilterRangeDateDto;
  clientId: string | null = null;
  client: ClientDto;
  currencyList: number[] = [];
  movementTypeList: number[] = [];
  statusList: number[] = [];
}

export interface TransferFiltersDtoFormGroup extends PaymentTransactionFiltersDtoFormGroup {
  accreditationConsolidationNumber?: any;
  accreditationOriginNumber?: any;
  accreditationByAccountId?: any;
  accreditationByAccount?: any;
  amount?: any;
  bankSourceId?: any;
  bankSource?: any;
  bankDestinationId?: any;
  bankDestination?: any;
  createdDate?: any;
  clientId?: any;
  client?: any;
  currencyList?: any;
  movementTypeList?: any;
  statusList?: any;
}
