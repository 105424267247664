<mf-page title="Filtros de búsqueda">
  <mf-row align="right">
    <mf-button-icon
      [icon]="icons.clearFilters"
      tooltip="Limpiar filtros"
      (click)="clearFiltersClick()" />
  </mf-row>
  @for (gridFilter of gridFiltersService.gridFilters; track $index) {
  <mf-grid-filter
    [gridFilter]="gridFilter"
    [ngModel]="gridFiltersService.editingFilters[gridFilter.propertyName]"
    (ngModelChange)="gridFiltersService.editingFilters[gridFilter.propertyName] = $event"
    (relatedIdChange)="onRelatedIdChange(gridFilter.propertyName, $event)" />
  }

  <mf-row-buttons>
    <mf-button
      text="Aplicar filtros"
      [icon]="icons.search"
      (click)="applyFiltersClick()" />
  </mf-row-buttons>
</mf-page>
