<mf-page title="¿Deshacer proceso de acreditación?">
  <mf-row>
    <p>
      Deshacer un proceso de acreditación puede resultar en pérdidas de información, inconsistencia de saldos en cuentas bancarias y
      pérdidas económicas. Asegúrese de que el archivo no haya sido ejecutado por el banco. Esta acción no podrá retomarse. Las órdenes
      serán consideradas en el próximo proceso de acreditaciones. En caso de no estar seguro de continuar, solicite asistencia.
    </p>
  </mf-row>

  <form [formGroup]="undoValidation">
    <mf-row>
      <p>
        Escribe a continuación la cantidad de transferencias a revertir.<strong>{{ accreditationByAccountCount }}</strong>
      </p>
      <mf-field-text
        label="Cantidad de transferencias a revertir"
        formControlName="accreditationsByAccount" />
      <mf-field-check
        label="Si, deseo deshacer el cierre."
        formControlName="checkUndoAccreditation" />
      <mf-row-buttons>
        <mf-button-secondary
          (click)="closeModal()"
          text="Cancelar"></mf-button-secondary>
        <mf-button
          [disabled]="!(numberIsValid && undoValidation.value.checkUndoAccreditation)"
          (click)="undoAccreditation()"
          text="Deshacer acreditación" />
      </mf-row-buttons>
    </mf-row>
  </form>
</mf-page>
