<mf-page title="Detalles del movimiento">
  @if (bankMovementDto) {
  <mf-column-display label="Id">{{ bankMovementDto.id }}</mf-column-display>
  <mf-column-display label="Tipo de movimiento">{{
    bankMovementDto.movementType | translate : enumName.AccreditationsByAccountType
  }}</mf-column-display>
  <mf-column-display label="Moneda">{{ bankMovementDto.currency | translate : enumName.Currency }}</mf-column-display>
  <mf-column-display label="Monto">{{ bankMovementDto.amount }}</mf-column-display>
  <mf-column-display label="Referencia">{{ bankMovementDto.reference || '-' }}</mf-column-display>
  <mf-column-display label="Id de conciliación">{{ bankMovementDto.conciliationRowId || '-' }}</mf-column-display>
  }
</mf-page>
