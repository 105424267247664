import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-order-popup',
  templateUrl: './order-popup.component.html',
  styleUrls: ['./order-popup.component.scss'],
})
export class OrderPopupComponent {
  constructor(modalService: ModalService) {
    this.transferId = modalService.openData;
  }

  transferId: string;
}
