{
	"_locale": "EsUy",
	"TransactionStatus": {
		"6": "Rechazada",
		"2": "Pendiente de envío",
		"5": "Cancelada",
		"3": "Pendiente de conciliar",
		"1": "Esperando carga del voucher",
		"4": "Finalizado"
	},
	"IssueStatus": {
		"3": "Resuelto",
		"1": "Nuevo",
		"2": "En Curso"
	},
	"EnventNotificationType": {
		"1": "Operación",
		"4": "Transferencia",
		"2": "Orden de cobro",
		"3": "Orden de pago"
	},
	"Roles": {
		"4": "Admin cliente",
		"3": "Oficial de cumplimiento",
		"1": "Super Admin",
		"2": "Ejecutor"
	},
	"IssueType": {
		"1": "Transferencia no conciliada",
		"7": "Transferencia cancelada",
		"8": "Transferencia rechazada",
		"4": "Persona inconsistente",
		"9": "La cuenta de origen de los fondos es requerida",
		"2": "Movimiento bancario no reconocido",
		"5": "KYC",
		"6": "Otro",
		"3": "Cuenta bancaria no validada"
	},
	"EnumTest": {
		"2": "Dos (Español)",
		"1": "Uno (Español - Uruguay)",
		"5": "Cinco",
		"4": "Cuatro",
		"3": "Tres (Español)"
	},
	"TransferOriginType": {
		"3": "Compensación",
		"1": "Operación",
		"2": "Devolución"
	},
	"OperationRequestValidationCode": {
		"2": "Los montos no coinciden",
		"3": "En Revisión",
		"1": "Campo requerido"
	},
	"TransferRequestValidationCode": {
		"4": "Error al crear la cuenta de origen",
		"6": "Los montos no coinciden",
		"1": "Campo requerido",
		"5": "Error al crear la transferencia",
		"7": "Error en las órdenes de cobro",
		"2": "Error al crear la persona o empresa",
		"3": "Error al obtener la cuenta de destino"
	},
	"AccreditationsByAccountType": {
		"2": "A otros bancos",
		"1": "Transferencias internas"
	},
	"AccreditationStatus": {
		"102": "Cancelado",
		"100": "Finalizado con éxito",
		"2": "Actualizando saldos",
		"101": "Finalizado con errores",
		"3": "Generando archivos",
		"4": "Esperando confirmación de carga de archivos",
		"1": "Pendiente"
	},
	"MovementType": {
		"2": "Crédito",
		"1": "Débito"
	},
	"OrderRequestValidationCode": {
		"2": "Error al crear la persona o empresa",
		"1": "Campo requerido",
		"3": "Error al crear la cuenta bancaria destino"
	},
	"PublicTransactionStatus": {
		"4": "Rechazada",
		"3": "Cancelada",
		"1": "Procesando",
		"2": "Completada"
	},
	"OperationStatus": {
		"2": "En revisión antes de cobrar",
		"9": "Cancelada",
		"6": "Pagando",
		"8": "Cancelando",
		"5": "Cobrado",
		"1": "Borrador",
		"3": "Cobrando",
		"4": "En revisión antes de pasar a cobrado",
		"7": "Finalizada"
	},
	"NotificationType": {
		"2": "Advertencia",
		"1": "Notificación",
		"3": "Alerta"
	},
	"OrderStatus": {
		"2": "Pendiente",
		"3": "Pago realizado",
		"1": "Borrador",
		"5": "Cancelada",
		"4": "Finalizado"
	},
	"AccreditationsByAccountStatus": {
		"4": "Cancelado",
		"1": "Pendiente",
		"2": "Archivo descargado",
		"3": "Finalizado"
	},
	"OrderType": {
		"2": "Pago",
		"1": "Cobro"
	},
	"PublicOrderStatus": {
		"4": "Cancelada",
		"2": "Procesando",
		"3": "Completada",
		"1": "Pendiente"
	},
	"IssueLevel": {
		"2": "Moderado",
		"3": "Critico",
		"1": "Normal"
	},
	"ValueListType": {
		"2": "Tipo societario",
		"1": "Industria"
	},
	"GatewayName": {
		"2": "Mce",
		"1": "MiPspc"
	},
	"EntityType": {
		"3": "Transferencia",
		"5": "Persona física",
		"4": "Persona jurídica",
		"6": "Movimiento bancario",
		"2": "Cuenta bancaria de pasarela",
		"1": "Cuenta bancaria"
	},
	"AccreditationType": {
		"2": "Compensaciones",
		"1": "Pagos"
	},
	"AccountType": {
		"1": "Caja de ahorros",
		"2": "Cuenta corriente"
	},
	"TransactionType": {
		"1": "Transferencia"
	},
	"Currency": {
		"1": "UYU",
		"2": "USD",
		"3": "ARS"
	},
	"CurrencyName": {
		"1": "Pesos Uruguayos",
		"2": "Dólares",
		"3": "Peso Argentino"
	}
}
