// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { TrackDto } from "../dtos/TrackDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { TrackFiltersDto } from "../dtos/TrackFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminTrack {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getPaged(
    filters: PagedSearchDto<TrackFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<TrackDto>> {
    return this._httpApiClient.post("/admin/track/paged", filters, httpApiRequestOptions);
  }

}
