// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class ResultDto<TEntity>  {
  echoId: number = 0;
  totalItemCount: number = 0;
}

export interface ResultDtoFormGroup<TEntity>  {
  echoId?: any;
  totalItemCount?: any;
}
