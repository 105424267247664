import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { GridFilterService } from '../grid-filter/service/grid-filter.service';
import { Icons } from '../../icon/icons';

@Component({
  selector: 'mf-grid-filters-popup',
  templateUrl: './grid-filters-popup.component.html',
  styleUrls: ['./grid-filters-popup.component.scss'],
})
export class GridFiltersPopupComponent {
  constructor(private _modalService: ModalService) {
    this.gridFiltersService = _modalService.openData.gridFiltersService;
  }

  gridFiltersService: GridFilterService;
  icons = Icons;

  applyFiltersClick() {
    this.gridFiltersService.applyFilters();
    this._modalService.closeAsSuccess();
  }

  clearFiltersClick() {
    this.gridFiltersService.clearFiltersAll();
  }

  onRelatedIdChange(propertyName: string, $event: string) {
    this.gridFiltersService.editingFilters[propertyName + 'Id'] = $event;
  }
}
