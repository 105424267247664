import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { ApiAdminAccreditationsByAccount } from '../../../../api/endpoints/ApiAdminAccreditationsByAccount';
import { AccreditationsByAccountDto } from '../../../../api/dtos/AccreditationsByAccountDto';
import { AccreditationsByAccountStatus } from '../../../../api/enums/AccreditationsByAccountStatus';
import { NavigationService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { AccreditationsByAccountVoucherDtoFormGroup } from '../../../../api/dtos/AccreditationsByAccountVoucherDto';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { ApiAdminUploadFile } from '../../../../api/endpoints/ApiAdminUploadFile';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { appRoutes } from '../../../app.routes';
import { AppRoute } from '../../../app.routes.path';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';

@Component({
  selector: 'mf-accreditations-by-account-card',
  templateUrl: './accreditations-by-account-card.component.html',
  styleUrls: ['./accreditations-by-account-card.component.scss'],
})
export class AccreditationsByAccountCardComponent implements OnInit {
  constructor(
    private _cfb: CustomFormBuilder,
    private _apiAdminAccreditationsByAccount: ApiAdminAccreditationsByAccount,
    private _apiAdminUploadFile: ApiAdminUploadFile,
    private _navigationService: NavigationService
  ) {}

  @Input()
  accreditationByAccount: AccreditationsByAccountDto;

  accreditationByAccountStatus = AccreditationsByAccountStatus;
  currentStatus = signal<AccreditationsByAccountStatus | null>(null);
  icons = Icons;
  enumName = enumName_mi_pspc;

  accreditationsByAccountVoucherForm = this._cfb.group<AccreditationsByAccountVoucherDtoFormGroup>({
    accreditationId: ['', Validators.required],
    accreditationsByAccountId: ['', Validators.required],
    imageUrl: [null, Validators.required, 'Comprobante de acreditación'],
  });

  endpoint = (fileList: FileList, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminUploadFile.uploadAccreditationByAccountVoucher(fileList, httpApiRequestOptions);

  ngOnInit(): void {
    this.accreditationsByAccountVoucherForm.patchValue({
      accreditationId: this.accreditationByAccount.accreditationId,
      accreditationsByAccountId: this.accreditationByAccount.id,
    });
    this.currentStatus.set(this.accreditationByAccount.status);
  }

  async downloadFileAndUpdateStatus(accreditationsByAccountDto: AccreditationsByAccountDto) {
    await this._apiAdminAccreditationsByAccount.setAsFileDownloaded(accreditationsByAccountDto.id);
    this.downloadFile(accreditationsByAccountDto);
    this.currentStatus.set(this.accreditationByAccountStatus.FileDownloaded);
  }

  downloadFile(accreditationsByAccountDto: AccreditationsByAccountDto) {
    this._navigationService.navigateNewTab(accreditationsByAccountDto.bankFileUrl);
  }

  navigateToTransfers(accreditationByAccount: AccreditationsByAccountDto) {
    this._navigationService.navigateNewTab(AppRoute.paymentTransactions.transfers.list, [
      'accreditationByAccountId=' + accreditationByAccount.id,
    ]);
  }

  async save() {
    await this._apiAdminAccreditationsByAccount.uploadVoucher(this.accreditationsByAccountVoucherForm.value);
    this.currentStatus.set(this.accreditationByAccountStatus.Completed);
  }
}
