<mf-form
  [formGroup]="configurationForm"
  [entity]="configurationDto"
  (save)="save($event)">
  <mf-column>
    <mf-field-text formControlName="domain" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="currencyListCsv" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="issueRenotifyTimeout" />
  </mf-column>
  <mf-row-separator [minHeight]="30"></mf-row-separator>
  <mf-column>
    <mf-field-text formControlName="daMapTemplateId" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="daMapUyuAccountId" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="daMapUsdAccountId" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="daMapUyuConciliationJobId" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="daMapUsdConciliationJobId" />
  </mf-column>
  <mf-column>
    <mf-field-check formControlName="skipAccountBalanceValidation" />
  </mf-column>
</mf-form>
