import { Component, EventEmitter, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getValue } from '../../../../ts-shared/src';
import { FiltersDto } from '../../../../ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { PagedResultDto } from '../../../../ts-shared/src/lib/api/searching/dtos/PagedResultDto';
import { PagedResultItemDto } from '../../../../ts-shared/src/lib/api/searching/dtos/PagedResultItemDto';
import { PagedSearchDto } from '../../../../ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { IHttpApiRequestOptions } from '../IHttpApiRequestOptions';
import { Icons } from '../icon/icons';
import { DateUtcPipe } from '../pipes/date-utc.pipe';
import { EnumTranslateProviderService } from '../services/enum-translate-provider.service';
import { ModalService } from '../services/modal.service';
import { GridFilterService } from './grid-filter/service/grid-filter.service';
import { GridFiltersPopupComponent } from './grid-filters-popup/grid-filters-popup.component';
import { GridMenuItem } from './grid-menu/grid-menu-item';
import { CellValueType, IGridColumn } from './interface/gird-column';
import { IGridFilter } from './interface/grid-filter';
import { SearchOrderDto } from '../../../../ts-shared/src/lib/api/searching/dtos/SearchOrderDto';

export enum GridMode {
  ServerSide,
  ClientSide,
}

interface IGridInternalColumn {
  column: IGridColumn<any>;
  calculatedWith: number;
}

export interface IGridActionButton {
  text: string;
  icon?: string;
  isSecondary?: boolean;
  onClick: () => void;
}

@Component({
  selector: 'mf-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [GridFilterService],
})
export class GridComponent implements OnInit {
  constructor(
    public gridFilterService: GridFilterService,
    private _enumTranslateProviderService: EnumTranslateProviderService,
    private _modalService: ModalService,
    private _sanitizer: DomSanitizer,
    private _dateUctPipe: DateUtcPipe
  ) {
    this.searchDto = new PagedSearchDto<any>();
    this.searchDto.pageSize = 25;
    this.searchDto.filters = {};
  }

  mode: GridMode = GridMode.ServerSide;

  @Input()
  actionButtonText: string;
  @Input()
  actionButtons: IGridActionButton[];
  @Input()
  disableActionButton = false;
  @Input()
  hideFilters = false;
  @Input()
  rowSelection = false;
  @Output()
  actionButtonClick = new EventEmitter();
  @Input()
  clientSideItems: any[];
  @Input()
  apiEndpoint: (filters: PagedSearchDto<any>, httpParamsOptions: IHttpApiRequestOptions) => Promise<PagedResultDto<any>>;

  @Input()
  clientSideApplyFilters: (clientSideItems: any[], filters: PagedSearchDto<any>) => any[];

  // @Input()
  // clientSideApplyOrder: (clientSideItems: any[], order: SearchOrderDto[]) => any[];

  @Input()
  columns: IGridColumn<any>[];
  @Input()
  mustIncludeFields!: string[];
  @Input()
  initialFilters: FiltersDto;
  @Input()
  menuItems: GridMenuItem[];

  @Output()
  selectedItems = new EventEmitter();

  @ViewChild('gridRef', { static: false })
  gridReference: any;

  gridMode = GridMode;

  page = signal<PagedResultDto<any> | null>(null);
  searchDto: PagedSearchDto<any>;
  isLoading = signal(false);
  errorMessage!: string;
  cellValueType = CellValueType;
  internalColumns = signal<IGridInternalColumn[]>([]);
  icons = Icons;
  allSelected = false;

  private calculateColumnsWith() {
    if (!this.gridReference || !this.gridReference.nativeElement) {
      return;
    }

    let gridWith = this.gridReference.nativeElement.getBoundingClientRect().width;

    if (this.menuItems) {
      gridWith -= 48;
    }

    const totalColumnsWith = this.columns.reduce((a, b) => a + (b.width ?? 100), 0);
    this.internalColumns.set(
      this.columns.map((c) => {
        return {
          column: c,
          calculatedWith: ((c.width ?? 100) / totalColumnsWith) * gridWith,
        };
      })
    );
  }

  ngOnInit() {
    if (this.clientSideItems) {
      this.mode = GridMode.ClientSide;
    }

    if (this.mode == GridMode.ServerSide && !this.apiEndpoint) {
      throw Error('apiEndpoint is required for server side grid');
    }

    const gridFilters = this.columns
      .filter((c) => !!c.filter)
      .map((c) => {
        if (c.filter && !c.filter.header) {
          c.filter.header = c.header;
        }
        return c.filter;
      }) as IGridFilter[];
    this.gridFilterService.init(gridFilters, this.initialFilters ? this.initialFilters : new FiltersDto());

    this.searchDto.includedFields = this.columns.map((c) => c.field);

    if (this.mustIncludeFields) {
      this.searchDto.includedFields = this.searchDto.includedFields.concat(this.mustIncludeFields);
    }
    this.searchDto.includedFields = Array.from(new Set(this.searchDto.includedFields));

    this.gridFilterService.filters.subscribe((filters) => {
      this.searchDto.filters = filters;
      this.reloadPage();
    });

    this.calculateColumnsWith();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculateColumnsWith();
    }, 100);
  }

  private loadPageClientSide() {
    this.isLoading.set(true);

    try {
      let filteredItemList = this.clientSideApplyFilters
        ? this.clientSideApplyFilters(this.clientSideItems, this.searchDto.filters)
        : this.clientSideItems;

      if (this.searchDto.orderBy.length > 0) {
        const order = this.searchDto.orderBy[0];
        filteredItemList = filteredItemList.sort((a, b) => {
          const valueA = getValue(a, order.columnName);
          const valueB = getValue(b, order.columnName);

          if (valueA < valueB) {
            return order.ascendent ? 1 : -1;
          }
          if (valueA > valueB) {
            return order.ascendent ? -1 : 1;
          }
          return 0;
        });
      }

      const pageSize = this.searchDto.pageSize ?? 1;
      const startIndex = pageSize * ((this.searchDto.page ?? 1) - 1);

      const pageItemList = filteredItemList.slice(startIndex, startIndex + pageSize).map((item) => {
        const itemResult = new PagedResultItemDto<any>();
        itemResult.item = item;
        return itemResult;
      });

      const pagedResult = new PagedResultDto<any>();
      pagedResult.currentPageIndex = this.searchDto.page ?? 1;
      pagedResult.currentPageItemCount = pagedResult.pageItemList.length;
      pagedResult.totalItemCount = filteredItemList.length;
      pagedResult.totalPageCount = Math.ceil(pagedResult.totalItemCount / pageSize);
      pagedResult.pageItemList = pageItemList;

      this.page.set(pagedResult);
    } catch (error: any) {
      this.errorMessage = error;
    }

    this.isLoading.set(false);
  }

  async reloadPage() {
    this.errorMessage = '';

    if (this.mode == GridMode.ClientSide) {
      this.loadPageClientSide();
      return;
    }

    this.isLoading.set(true);
    try {
      this.page.set(await this.apiEndpoint(this.searchDto, { preventSpinner: true, preventErrorHandler: true }));
    } catch (error: any) {
      this.errorMessage = error.message;
    }
    this.isLoading.set(false);
  }

  sortBy(column: IGridColumn<any>) {
    const ascendent = this.isSortingByAsc(column) ? false : true;
    this.searchDto.orderBy = [{ columnName: column.orderBy ?? column.field, ascendent: ascendent }];
    this.reloadPage();
  }

  isSortingBy(column: IGridColumn<any>) {
    const orderBy = this.searchDto.orderBy.find((c) => c.columnName == (column.orderBy ?? column.field));
    return orderBy != null;
  }

  isSortingByAsc(column: IGridColumn<any>) {
    const orderBy = this.searchDto.orderBy.find((c) => c.columnName == (column.orderBy ?? column.field));
    return orderBy != null ? orderBy.ascendent : false;
  }

  renderColumnValue(row: PagedResultItemDto<any>, column: IGridColumn<any>) {
    var value = getValue(row.item, column.field);

    if (column.render) {
      return this._sanitizer.bypassSecurityTrustHtml(column.render(row.item));
    }

    if (column.cellOptions?.type == CellValueType.Numeric) {
      const formatter = new Intl.NumberFormat('es-UY', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    }

    if (column.cellOptions?.type == CellValueType.Date) {
      return this._dateUctPipe.transform(
        value,
        column.cellOptions?.dateIncludeMilliseconds || column.cellOptions?.dateIncludeTime,
        column.cellOptions?.dateIncludeMilliseconds
      );
    }

    if (column.cellOptions?.type == CellValueType.Enum) {
      if (!column.cellOptions.enumName) {
        throw Error('enumName is required for enum type column');
      }
      return this._enumTranslateProviderService.translateEnum(column.cellOptions.enumName, value, column.cellOptions.enumNameModule);
    }

    if (column.cellOptions?.type == CellValueType.Boolean) {
      return value ? 'Sí' : 'No';
    }

    return value;
  }

  toggleFiltersPopup() {
    this._modalService.openRightModalSmall(GridFiltersPopupComponent, { openData: { gridFiltersService: this.gridFilterService } });
  }

  refresh() {
    this.reloadPage();
  }

  selectAll(selected: boolean): void {
    const pageItems = this.page()?.pageItemList;
    if (!pageItems) return;

    if (selected) {
      pageItems.forEach((row) => {
        if (!row.selected) {
          row.selected = true;
        }
      });
    } else {
      pageItems.forEach((row) => {
        if (row.selected) {
          row.selected = false;
        }
      });
    }

    this.allSelected = pageItems.every((row) => row.selected);

    const selectedItems = pageItems.filter((row) => row.selected).map((row) => row.item);

    this.selectedItems.emit(selectedItems);
  }

  selectItem(item: PagedResultItemDto<any>): void {
    const pageItems = this.page()?.pageItemList;
    if (!pageItems) return;

    pageItems.forEach((pageItem) => {
      if (pageItem.item.id === item.item.id) {
        pageItem.selected = !pageItem.selected;
      }
    });

    this.allSelected = pageItems.every((row) => row.selected);

    const selectedItems = pageItems.filter((row) => row.selected).map((row) => row.item);

    this.selectedItems.emit(selectedItems);
  }

  onActionButtonClick(eventCallback: any) {
    eventCallback();
  }
}
